<template>
  <div class="" v-if="hasPerm('payments.view_invoice')">
    <page-header title="Exports Facturation" icon="fa fa-file-excel"></page-header>
    <div class="header-line header-faint">
      <date-range-selector @change="onDateRangeChanged"></date-range-selector>
    </div>
    <div class="sub-header-line">
      <check-box-select
        :choices="schoolYears"
        :initial-value="schoolYears"
        @init="onSelectedYearsChanged"
        @changed="onSelectedYearsChanged"
        inline
      >
      </check-box-select>
    </div>
    <div class="export-block">
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <b>Exports</b>
          </b-col>
          <b-col cols="8">
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-file-invoice"></i> État des ventes</h3>
          </b-col>
          <b-col cols="8">
            Les ventes annulées et remboursées sont incluses. Les annulations non facturées sont exclues.
          </b-col>
          <b-col class="text-right" cols="2">
            <a
              href
              @click.prevent="downloadSalesAndCancellations"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col>
            <h3><i class="fa fa-credit-card"></i> Paiements</h3>
          </b-col>
          <b-col class="text-right">
            <a
              href
              @click.prevent="downloadPayments"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="export-block" v-if="cielExport">
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <b>Exports CIEL</b>
          </b-col>
          <b-col cols="4">
            <b-form-select v-model="cielFreezeOption" id="cielFreezeOption">
              <b-select-option value="">Mode standard</b-select-option>
              <b-select-option value="freeze">Figer les éléments de l'export</b-select-option>
              <b-select-option value="include">Inclure les éléments déjà figés</b-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="6" class="small-text">
            <div v-if="cielFreezeOption !== 'include'">
              Tous les éléments de la période seront inclus sauf ceux figés précédemment
            </div>
            <div v-else>
              Tous les éléments de la période seront inclus y compris ceux figés précédemment
            </div>
            <div v-if="cielFreezeOption === 'freeze'" class="freeze-warning">
              <i class="fa fa-warning"></i>
              Les éléments de cet export seront figés
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-file-invoice"></i> Ventes</h3>
          </b-col>
          <b-col cols="8">
            Les ventes annulées et remboursées sont incluses. Les annulations non facturées sont exclues.
          </b-col>
          <b-col class="text-right" cols="2">
            <a
              href
              @click.prevent="downloadCielSales"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-credit-card"></i> Paiements</h3>
          </b-col>
          <b-col cols="3">
            <b-form-select v-model="cielPaymentsOption" id="cielPaymentsOption">
              <b-select-option value=""></b-select-option>
              <b-select-option value="all">Tout</b-select-option>
              <b-select-option value="no-waiting">Tout sauf les en attente (remboursements inclus)</b-select-option>
              <b-select-option value="deposit">Seulement les paiements déposés</b-select-option>
              <b-select-option value="waiting">Seulement les paiements en attente</b-select-option>
              <b-select-option value="refunds">Seulement les remboursements</b-select-option>
            </b-form-select>
          </b-col>
          <b-col></b-col>
          <b-col class="text-right">
            <a
              href
              @click.prevent="downloadCielPayments"
              class="btn btn-secondary"
              :class="{ disabled: !cielPaymentsDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="export-block">
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <b>Compatibilité</b>
          </b-col>
          <b-col cols="8">
            Exports pour compatibilité
          </b-col>
        </b-row>
      </div>
      <div class="export-line">
       <b-row>
         <b-col cols="2">
           <h3><i class="fa fa-file-invoice"></i> Ventes</h3>
         </b-col>
         <b-col cols="8">
           Ventes facturées. Les ventes annulées et remboursées sont exclues.
         </b-col>
         <b-col class="text-right" cols="2">
           <a href @click.prevent="downloadSales" class="btn btn-secondary" :class="{ disabled: !isDownloadActive }">
             <i class="fa fa-file-excel"></i> Télécharger
           </a>
         </b-col>
       </b-row>
      </div>
      <div class="export-line">
        <b-row>
          <b-col cols="2">
            <h3><i class="fa fa-file-invoice"></i> État des ventes (compatibilité)</h3>
          </b-col>
          <b-col cols="8">
            Les ventes annulées et remboursées sont incluses. Les annulations non facturées sont incluses.
          </b-col>
          <b-col class="text-right" cols="2">
            <a
              href
              @click.prevent="downloadSalesAndCancellationsCompat"
              class="btn btn-secondary"
              :class="{ disabled: !isDownloadActive }"
            >
              <i class="fa fa-file-excel"></i> Télécharger
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapMutations } from 'vuex'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { BackendApi, openDocument } from '@/utils/http'
import { makeSchoolYear } from '@/types/schools'

export default {
  name: 'InvoicesExports',
  mixins: [BackendMixin],
  components: {
    CheckBoxSelect,
    PageHeader,
    DateRangeSelector,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      schoolYears: [],
      selectedSchoolYearIds: [],
      cielPaymentsOption: '',
      cielFreezeOption: '',
    }
  },
  mounted() {
    this.loadSchoolYears()
  },
  computed: {
    isDownloadActive() {
      return this.startDate && this.endDate
    },
    cielPaymentsDownloadActive() {
      return this.isDownloadActive && (this.cielPaymentsOption !== '')
    },
    cielExport() {
      return store.getters.config.cielExport
    },
    schoolYearIds() {
      return this.selectedSchoolYearIds.join('-')
    },
  },
  methods: {
    ...mapActions(['addSuccess', 'addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async downloadSales() {
      const docUrl = '/export-ventes/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate + '--no--' + this.schoolYearIds
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadSalesAndCancellations() {
      const docUrl = '/export-ventes-et-annulations/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate + '--no--' + this.schoolYearIds
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadSalesAndCancellationsCompat() {
      const docUrl = '/export-ventes-et-annulations/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate + '--compat--' + this.schoolYearIds
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadPayments() {
      const docUrl = '/export-paiements/<key>/'
      const docSlug = '' + this.startDate + '--' + this.endDate + '--no--' + this.schoolYearIds
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadCielSales() {
      const docUrl = '/export-ciel-ventes/<key>/'
      const frozen = '--' + this.cielFreezeOption
      const docSlug = '' + this.startDate + '--' + this.endDate + '--no--' + this.schoolYearIds + frozen
      try {
        await openDocument(docUrl, docSlug)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async downloadCielPayments() {
      if (this.cielPaymentsOption) {
        const docUrl = '/export-ciel-paiements/<key>/'
        const toggle = '--' + this.cielPaymentsOption + '--'
        const frozen = '--' + this.cielFreezeOption
        const docSlug = '' + this.startDate + '--' + this.endDate + toggle + this.schoolYearIds + frozen
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSelectedYearsChanged(event) {
      this.selectedSchoolYearIds = event.choices.map(elt => elt.id)
    },
  },
}
</script>

<style scoped lang="less">
.header-line1 {
  background: #000;
}
.export-line {
  padding: 5px 0;
  margin: 5px;
  border-bottom: solid 1px #ccc;
}
.export-line:last-of-type {
  padding: 5px 0;
  margin: 5px;
  border-bottom: none;
}
.export-block {
  margin-bottom: 50px;
  padding: 10px;
  background: #e0e0e0;
}
.export-block:last-of-type {
  margin-bottom: 0;
}
.sub-header-line {
  background: #ccc;
  padding: 5px;
  margin-bottom: 10px;
}
.freeze-warning {
  color: #bd3030;
  font-weight: bold;
}
.dark-mode {
  .export-block {
    background: #444;
  }
  .sub-header-line {
    background: #444;
  }
  .freeze-warning {
    color: #edb98f !important;
  }
}
</style>
